<template>
  <b-card
    :title="editable ? 'Editar título de tarefa' : 'Novo Titulo de tarefa'"
    class="text-center text-primary d-flex align-items-center"
  >
    <validation-observer ref="formUser">
      <b-row class="text-left mt-4 d-flex justify-content-center">
        <!-- name -->
        <b-col cols="12">
          <b-form-group>
            <label
              for="name"
              class="text-center font-weight-bold text-primary"
              >Nome :</label
            >
            <validation-provider
              #default="{ errors }"
              :custom-messages="{ required: 'Este campo é obrigatório' }"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="item.name"
                placeholder="Insira o título para associar a uma tarefa"
              ></b-form-input>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- <b-col cols="12">
          <div class="d-flex flex-column">
            <label
              for="email"
              class="text-left font-weight-bold text-primary mb-1"
            >
              Selecione o projeto:
            </label>

            <validation-provider
              #default="{ errors }"
              :custom-messages="{ required: 'Este campo é obrigatório' }"
              rules="required"
            >
              <div class="w-100">
                <v-select
                  v-model="item.projectSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Selecione"
                  :options="projectsArr"
                  label="name"
                  @input="getProjectStages()"
                >
                  <div slot="no-options">
                    Desculpe, mas não existe projeto cadastrado!
                  </div>
                  <template #list-header>
                    <li
                      class="add-new-header d-flex align-items-center my-50 cursor-pointer"
                      @click="$router.push({ name: 'novo-projeto' })"
                    >
                      <feather-icon icon="PlusIcon" size="16" />
                      <span class="align-middle ml-25">Add Novo Projeto</span>
                    </li>
                  </template>
                </v-select>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col> -->
      </b-row>
  

      <b-row v-if="item.projectSelected" class="text-left mt-2 d-flex justify-content-center">
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label
              for="email"
              class="text-left font-weight-bold text-primary mb-1"
            >
              Pertence a seguinte etapa:
            </label>

            <validation-provider
              #default="{ errors }"
              :custom-messages="{ required: 'Este campo é obrigatório' }"
              rules="required"
            >
              <div class="w-100">
                <!-- Garante que o v-select ocupe a largura total -->
                <v-select
                  v-model="item.projectStageSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Selecione"
                  :options="projectsStageArr"
                  label="name"
                >
                  <div slot="no-options">
                    Desculpe, mas o projeto não tem etapa!
                  </div>
                  <template #list-header>
                    <li
                      class="add-new-header d-flex align-items-center my-50 cursor-pointer"
                      @click="$router.push({ name: 'novo-projeto' })"
                    >
                      <feather-icon icon="PlusIcon" size="16" />
                      <span class="align-middle ml-25">Add Novo Projeto</span>
                    </li>
                  </template>
                </v-select>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <b-row class="justify-content-between d-flex p-2">
        <!-- back -->
        <b-button
          :disabled="loading"
          variant="warning"
          class="mr-2"
          @click="goBack"
          >Voltar
        </b-button>

        <div class="d-flex justify-content-between">
          <!-- saveUser -->
          <b-button
            variant="primary"
            @click="!editable ? saveItem() : updateItem()"
            :disabled="item.name.length < 1 || loading "
          >
            <span v-if="!loading ">{{ editable ? 'Atualizar' : 'Salvar' }}</span>
            <b-spinner v-if="loading"></b-spinner>
          </b-button>
        </div>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
    id: {
      type: String
    }
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data: () => ({
    projectsArr: [],
    
    projectsStageArr: [],
    
    item: {
      name: '',
      projectSelected: '',
      projectStageSelected: '',
    },
    required,
    loading: false,
    modalInactivate: false,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
  }),
  created() {
    //this.getAllProjects();
    if(this.editable){
      this.getTaskName();
    }
  },
  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },
  methods: {
    getTaskName(){
      this.$store.dispatch('getTaskName', this.id).then((resp) => {
        console.log(resp)
          this.item.name = resp.data.task_title;
      })
    },

    getAllProjects() {
      let body = {
        id: this.$route.params.id,
        page: 1,
        perPage: 1000,
        archived: false,
        workspace_id: this.$store.getters.currentWorkspace.id,
      };
      this.$store
        .dispatch('_getProjects', body)
        .then((resp) => {
          resp.data.map((item) => {
            let obj = {
              name: item.project_name,
              project_id: item.id,
              project_stages: item.project_stages ? item.project_stages : null,
            };
            this.projectsArr.push(obj);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProjectStages() {
      this.projectsStageArr = [];

      if (this.item.projectSelected && this.item.projectSelected.project_stages) {
        const filteredStages = this.item.projectSelected.project_stages.map(
          (stage) => ({
            project_id: stage.project_id,
            name: stage.stage_name,
            id: stage.id
          })
        );

        this.projectsStageArr.push(...filteredStages);
      }
    },
    saveItem() {
      this.$refs.formUser.validate().then((success) => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch('saveTaskTitle', {
              workspace_id: this.currentWorkspace.id,
              task_title: this.item.name,
            })
            .then(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Titulo de tarefa criado com sucesso.',
                  icon: 'XIcon',
                  variant: 'success',
                },
              });
              this.$router.push({name: 'titulo-de-tarefas'})
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao  criar Titulo de tarefa',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
    updateItem() {
      console.log('Item antes da chamada:', this.id);

      this.$refs.formUser.validate().then((success) => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch('updateTaskTitle', {
              id: this.id,
              task_title: this.item.name,
            })
            .then(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Titulo de tarefa atualizado com sucesso.',
                  icon: 'XIcon',
                  variant: 'success',
                },
              });
            this.$router.push({name: 'titulo-de-tarefas'})
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao atualizar Titulo de tarefa',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
